import { FormControlLabel, FormGroup, Grid, Skeleton } from "@mui/material"
import { Account, AccountType, Location, MinimalStore, Store } from "../../apis/ersys-data/api"
import { Control } from "react-hook-form"
import FormInputText from "../forms/FormInputText"
import { FormInputDropdown, FormInputDropdownOption } from "../forms/FormInputDropdown"
import FormInputNumber from "../forms/FormInputNumber"
import FormInputCheckbox from "../forms/FormInputCheckbox"
import { usePermissions } from "../usePermissions"

interface AccountGeneralTabProps {
    control: Control<Account, any>
    stores?: MinimalStore[]
    locations?: Location[] 
    store?: Store,
    showSkeleton: () => boolean
    formSpacing: number
}

const AccountGeneralTab: React.FC<AccountGeneralTabProps> = ({ control, store, stores, locations, showSkeleton, formSpacing }) => {
    const { hasPermissions } = usePermissions()

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={20} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={200} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    const storeOptions = (): FormInputDropdownOption[] => {
        if(!stores) {
            return []
        }
        return [
            {
                label: "Select Store",
                value: 0
            },
            ...stores.map(s => {
                return {
                    label: s.fullName,
                    value: s.id
                }
            })
        ]
    }

    const locationOptions = (): FormInputDropdownOption[] => {
        if(!locations) {
            return []
        }
        return [
            {
                label: "Select Location",
                value: 0
            },
            ...locations.map(l => {
                return {
                    label: l.name,
                    value: l.id
                }
            })
        ]
    }

    const accountTypeOptions = () => {
      return (Object.keys(AccountType) as Array<keyof typeof AccountType>).map(k => {
        return {
          label: k,
          value: AccountType[k].toString()
        } as FormInputDropdownOption
      })
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputText
                            spacing={formSpacing}
                            name={"primaryFirstName"}
                            control={control}
                            label={"Primary First Name"}
                            rules={{ required: "Required Field", maxLength: 50 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"primaryLastName"}
                            control={control}
                            label={"Primary Last Name"}
                            rules={{ required: "Required Field", maxLength: 50 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"primaryEmail"}
                            control={control}
                            label={"Primary Email"}
                            rules={{ required: "Required Field", maxLength: 100, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i  }}
                        />
                        <FormInputDropdown
                            spacing={formSpacing}
                            name={"storeId"}
                            control={control}
                            label={"Store"}
                            options={storeOptions()}
                        />
                        <FormInputDropdown
                            spacing={formSpacing}
                            name={"locationId"}
                            control={control}
                            label={"Location"}
                            options={locationOptions()}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"room"}
                            control={control}
                            label={"Room"}
                            rules={{ required: false, maxLength: 12 }}
                        />
                        <FormInputDropdown
                            spacing={formSpacing}
                            name={"accountType"}
                            control={control}
                            label={"Account Type"}
                            options={accountTypeOptions()}
                        />

                        { store && store.isCustom1Enabled && <FormInputText
                            spacing={formSpacing}
                            name={"custom1"}
                            control={control}
                            label={store.custom1}
                            rules={{ required: false, maxLength: 12 }}
                        /> }
                    </FormGroup>)}
            </Grid>

            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputText
                            spacing={formSpacing}
                            name={"primaryPhone"}
                            control={control}
                            label={"Phone"}
                            rules={{ maxLength: 25 }}
                        />
                        <FormInputNumber
                            spacing={formSpacing}
                            name={"graduationYear"}
                            control={control}
                            label={"Graduation Year"}
                            rules={{ min: 1970 }}
                        />
                        <FormInputCheckbox
                            spacing={formSpacing}
                            name={"isActive"}
                            control={control}
                            label={"Is Active"}
                        />
                        <FormInputCheckbox
                            spacing={formSpacing}
                            name={"isFlaggedForQuality"}
                            control={control}
                            label={"Is Flagged For Quality"}
                        />
                        <FormInputCheckbox
                            spacing={formSpacing}
                            name={"isHung"}
                            control={control}
                            label={"Hanger By Request"}
                        />

                        {hasPermissions("AP:TXTD") &&
                            <FormInputCheckbox name="isTextingShutoff" control={control} label="Shutoff Texting" spacing={formSpacing} />
                        }
                    </FormGroup>)}
            </Grid>
        </Grid>
    )
}

export default AccountGeneralTab