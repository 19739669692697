import { Checkbox, FormControlLabel, Grid, Paper, Skeleton, Typography } from "@mui/material"
import { User } from "../../apis/ersys-data/api"
import { useEffect, useState } from "react"
import groupBy from "../../functions/groupby"

interface UserPermissionsProps {
    user?: User
    onPermissionsChange: (perms: string[]) => void
}

interface UserPermission {
    displayName: string,
    value: string,
    set: string,
}

const permissionsSet: UserPermission[] = [
    {
        displayName: "User Management",
        value: "AP:U",
        set: "Admin"
    },
    {
        displayName: "Global Plans Page",
        value: "AP:GP",
        set: "Inventory/Plans"
    },
    {
        displayName: "Payment Plans",
        value: "AP:PPP",
        set: "Inventory/Plans"
    },
    {
        displayName: "Departments Page",
        value: "AP:DP",
        set: "Inventory/Plans"
    },
    {
        displayName: "Discounts Page",
        value: "AP:DCP",
        set: "Inventory/Plans"
    },
    {
        displayName: "Inventory Categories Page",
        value: "AP:ICP",
        set: "Inventory/Plans"
    },
    {
        displayName: "Accounting Department Access",
        value: "AP:Acct",
        set: "Other"
    },
    {
        displayName: "Delivery Texting Acess",
        value: "AP:TXTD",
        set: "Other"
    },    
    {
        displayName: "Bag Reprint",
        value: "AP:BRP",
        set: "Production Tools"
    },
    {
        displayName: "Bag Print Queue",
        value: "AP:BQP",
        set: "Production Tools"
    },
    {
        displayName: "Stores Page",
        value: "AP:SP",
        set: "Stores"
    },
    {
        displayName: "Create Store",
        value: "AP:SP+",
        set: "Stores"
    },
    {
        displayName: "General Knowledge Tab",
        value: "AP:SP:G",
        set: "Stores"
    },
    {
        displayName: "Production Tab",
        value: "AP:SP:P",
        set: "Stores"
    },
    {
        displayName: "Accounts Page",
        value: "AP:AP",
        set: "Accounts"
    },
    {
        displayName: "Create Account",
        value: "AP:AP+",
        set: "Accounts"
    },
    {
        displayName: "Modify Plans",
        value: "AP:AP:MP",
        set: "Accounts"
    },
    {
        displayName: "Cancel Orders",
        value: "AP:O-",
        set: "Accounts"
    },
    {
        displayName: "Driver Tools Page",
        value: "AP:DTP",
        set: "Drivers"
    },
    {
        displayName: "Order Form Page",
        value: "AP:OF",
        set: "Ordering"
    },
    {
        displayName: "ManualCC Payment Type",
        value: "AP:OF:MCC",
        set: "Ordering"
    },
    {
        displayName: "Cash Payment Type",
        value: "AP:OF:CA",
        set: "Ordering"
    },
    {
        displayName: "Check Payment Type",
        value: "AP:OF:CH",
        set: "Ordering"
    },
    {
        displayName: "Custom Discount",
        value: "AP:OF:D++",
        set: "Ordering"
    },
    {
        displayName: "Skip Confirmation Email",
        value: "AP:OF:SO",
        set: "Ordering"
    },
    {
        displayName: "Administrative Tools Page",
        value: "AP:ATP",
        set: "Bulk Tools"
    },
    {
        displayName: "Update Store Plans",
        value: "AP:ATP:USP",
        set: "Bulk Tools"
    },
    {
        displayName: "Create Accounts",
        value: "AP:ATP:CA",
        set: "Bulk Tools"
    },
    {
        displayName: "Rollup Accounts",
        value: "AP:ATP:RA",
        set: "Bulk Tools"
    },
    {
        displayName: "Assign Plans",
        value: "AP:ATP:AP",
        set: "Bulk Tools"
    },
    {
        displayName: "Merge Accounts",
        value: "AP:ATP:MA",
        set: "Bulk Tools"
    },
    {
        displayName: "Calculate Previous Balances",
        value: "AP:ATP:CS",
        set: "Bulk Tools"
    },
    {
        displayName: "Post Transactions",
        value: "AP:ATP:PT",
        set: "Bulk Tools"
    },
    {
        displayName: "On Account Charging",
        value: "AP:ATP:OAC",
        set: "Bulk Tools"
    },
]

const UserPermissionsTab: React.FC<UserPermissionsProps> = ({ user, onPermissionsChange }) => {
    const [claimLookup, setClaimLookup] = useState<Record<string, boolean>>({})

    useEffect(() => {
        const initialClaimLookup: Record<string, boolean> = {}
        if (!user) {
            return
        }
        for (const claim of user.claims) {
            initialClaimLookup[claim] = true
        }

        setClaimLookup(initialClaimLookup)
    }, [setClaimLookup, user])

    const showSkeleton = () => {
        return !user
    }

    const onClaimChange = (claimName: string, event: React.ChangeEvent<HTMLInputElement>) => {
        const newClaims = Object.assign({}, claimLookup)
        newClaims[claimName] = event.target.checked
        setClaimLookup(newClaims)

        onPermissionsChange(getClaimsList(newClaims))
    }

    const getClaimsList = (claimLookup: Record<string, boolean>): string[] => {
        const returnSet = []
        for (const perm of permissionsSet) {
            if (claimLookup[perm.value]) {
                returnSet.push(perm.value)
            }
        }
        return returnSet
    }

    const lookupClaim = (name: string) => {
        return claimLookup[name] ?? false
    }

    const renderSkeleton = () => {
        return (
            [...Array(5)].map((_, i) => (

                <FormControlLabel
                    control={<Skeleton variant='rectangular' width={20} height={20} sx={{ marginRight: 2 }} />}
                    key={`skele-${i}`}
                    label={<Skeleton variant='rectangular' width={200} height={20} />}
                    sx={{ margin: 1.5 }} />
            )
            )
        )
    }

    const renderPermissionSets = () => {
        const groups = groupBy(permissionsSet, p => p.set)
        const keys = Object.keys(groups)
        keys.sort()

        return keys.map(k => renderPermissionSet(groups[k] ?? []))
    }

    const renderPermissionSet = (set: UserPermission[]) => {
        return set && set.length > 0 &&
            <Paper key={set[0].set} sx={{width: "100%", margin: 2, padding: 2}}>
                <Typography variant="h6" sx={{marginBottom: 2}}>{set[0].set}</Typography>
                {
                    showSkeleton() ? renderSkeleton() :
                        set.map(p => (
                            <FormControlLabel
                                control={<Checkbox />}
                                key={p.value}
                                label={p.displayName}
                                checked={lookupClaim(p.value)}
                                onChange={e => onClaimChange(p.value, e as React.ChangeEvent<HTMLInputElement>)} />
                        ))}
            </Paper>
    }

    return (
        <Grid container spacing={2}>
            {renderPermissionSets()}
        </Grid>
    )
}

export default UserPermissionsTab